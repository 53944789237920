<template>
  <div class="flex-grow scroll-area">
    <div class="content md checkin-commute">
      <div class="edit-form" v-show="edit">
        <checkin-rule-form ref="ruleForm" :rule="form"></checkin-rule-form>
        <a-space class="footer">
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="handleConfirm">保存</a-button>
        </a-space>
      </div>
      <div class="detail-ctx" v-if="detail" v-show="!edit">
        <div class="detail-info">
          <div class="other-title">规则设置</div>
          <div class="set-info-item">
            <div class="set-info-label">规则名称：</div>
            <div class="set-info-ctx">{{ detail.name }}</div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">打卡人员：</div>
            <div class="set-info-ctx">
              <select-book :edit="false" multiple type="book" v-model="detail.user_ids"></select-book>
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">规则类型：</div>
            <div class="set-info-ctx">{{ getTypeText(detail.type) }}</div>
          </div>
          <div class="set-info-item" v-if="detail.type == 1">
            <div class="set-info-label">打卡时间：</div>
            <div class="set-info-ctx">
              <checkin-rule-time v-model="detail.ruleTimeDetail" :rule="detail" mode="edit" :editable="false"></checkin-rule-time>
            </div>
          </div>
          <template v-else-if="detail.type == 2">
            <div class="set-info-item">
              <div class="set-info-label">打卡时间：</div>
              <div class="set-info-ctx">{{ getDaysText(detail.freeRuleTime) }}</div>
            </div>
            <div class="set-info-item">
              <div class="set-info-label">打卡时间间隔：</div>
              <div class="set-info-ctx">{{ detail.freeTimeSpace }}分钟</div>
            </div>
          </template>
          <div class="set-info-item">
            <div class="set-info-label">特殊日期：</div>
            <div class="set-info-ctx">
              <checkin-special-date v-model="detail.special_config" show v-if="detail.special_config"></checkin-special-date>
              <span v-else>-</span>
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">节假日：</div>
            <div class="set-info-ctx">
              <a-checkbox v-model="detail.holiday_config" disabled>
                中国法定节假日不用打卡<span class="rule-form-tip">法定节假日、调休期间不用打卡，补班日期需打卡</span>
              </a-checkbox>
            </div>
          </div>
          <div class="other-title">打卡方式</div>
          <div class="set-info-item">
            <div class="set-info-label">选择方式：</div>
            <div class="set-info-ctx">
              {{ methodText(detail.method) }}
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">打卡位置：</div>
            <div class="set-info-ctx">
              <checkin-rule-location v-model="detail.location_config" show></checkin-rule-location>
            </div>
          </div>
          <div class="other-title">补卡设置</div>
          <div class="set-info-item">
            <div class="set-info-label">补卡申请：</div>
            <div class="set-info-ctx">
              <a-checkbox v-model="detail.allow_complement" disabled>
                中国法定节假日不用打卡<span class="rule-form-tip">法定节假日、调休期间不用打卡，补班日期需打卡</span>
              </a-checkbox>
              <template v-if="detail.allow_complement">
                <div class="set-info-item">
                  <div class="set-info-label">允许补卡时间限制：</div>
                  <div class="set-info-ctx">{{ getTimeLimitText(detail.timeLimit) }}</div>
                </div>
                <div class="set-info-item">
                  <div class="set-info-label">每月允许补卡次数：</div>
                  <div class="set-info-ctx">{{ getCountText(detail.count) }}</div>
                </div>
                <div class="set-info-item">
                  <div class="set-info-label">每月补卡截止日期：</div>
                  <div class="set-info-ctx">{{ getDateLimitText(detail.dateLimit) }}</div>
                </div>
              </template>
            </div>
          </div>
          <div class="other-title">更多设置</div>
          <div class="set-info-item">
            <div class="set-info-label">手机提醒：</div>
            <div class="set-info-ctx">
              默认不发送未打卡提醒
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">范围外打卡：</div>
            <div class="set-info-ctx">
              不在打卡范围内不能打卡，提示您不在打卡范围内
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">外出打卡记录同步：</div>
            <div class="set-info-ctx">
              <a-checkbox :checked="true" disabled>
                开启
                <span class="rule-form-tip">
                  开启后，员工在上下班期间外出打卡记录将同步至上下班中，结果显示为「外出打卡」
                </span>
              </a-checkbox>
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">生效时间：</div>
            <div class="set-info-ctx">
              {{ effectTimeText(detail.effect_flag) }}
            </div>
          </div>
        </div>
        <a-space class="footer">
          <a-button type="primary" @click="showEdit">编辑</a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import { clone, omit } from "../../../common/js/tool";
import {
  default_complement_config,
  methodOptions,
  effectTimeOptions,
  timeOptions,
  countOptions,
  dateOptions,
  weekDayOptions,
} from "./rule";
import CheckinRuleForm from "./component/CheckinRuleForm";
import CheckinRuleTime from "./component/CheckinRuleTime";
import CheckinSpecialDate from "./component/CheckinSpecialDate";
import CheckinRuleLocation from "./component/CheckinRuleLocation";

export default {
  name: "CheckinCommute",
  components: {
    CheckinRuleForm,
    CheckinRuleTime,
    CheckinSpecialDate,
    CheckinRuleLocation,
  },
  data() {
    return {
      edit: false,
      detail: null,
      form: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    dayOptions() {
      return weekDayOptions.map((item) => omit(item, "title"));
    },
  },
  created() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$axios({ url: `/admin/checkin-rule/${this.id}?expand=ruleTimeDetail`, method: "GET" }).then((res) => {
        this.dealInfo(res);
      });
    },
    getDaysText(freeRuleTime) {
      let res = [];
      freeRuleTime?.forEach((item) => {
        let target = this.dayOptions.find((day) => day.value == item);
        target && res.push(target.label);
      });
      return res.join(",");
    },
    getTimeLimitText(timeLimit) {
      let target = timeOptions.find((item) => item.value == timeLimit);
      return target?.label;
    },
    getCountText(count) {
      let target = countOptions.find((item) => item.value == count);
      return target?.label;
    },
    getDateLimitText(dateLimit) {
      let target = dateOptions.find((item) => item.value == dateLimit);
      return target?.label;
    },
    getTypeText(type) {
      let text = "";
      if (type == 1) {
        text = "固定上下班";
      } else if (type == 2) {
        text = "自由上下班";
      }
      return text;
    },
    methodText(method) {
      let target = methodOptions.find((item) => item.value == method);
      return target?.label;
    },
    effectTimeText(effect_flag) {
      let text = "";
      if (effect_flag) {
        let target = effectTimeOptions.find((item) => item.value == effect_flag);
        text = target?.label;
      }
      return text;
    },
    dealInfo(res) {
      let detail = {
        id: res.id,
        name: res.name,
        user_ids: res.user_ids && JSON.parse(res.user_ids),
        type: res.type,
        special_config: res.special_config,
        holiday_config: res.holiday_config == 1 ? true : false,
        method: res.method,
        location_config: res.location_config && JSON.parse(res.location_config),
        allow_complement: !!res.allow_complement,
        effect_flag: res.effect_flag,
        ruleTimeDetail: res.ruleTimeDetail,
      };
      let free_check_config = res.free_check_config;
      if (free_check_config) {
        free_check_config = JSON.parse(free_check_config);
        Object.assign(detail, free_check_config);
      }
      // 补卡设置
      let complement_config = clone(default_complement_config);
      if (res.complement_config) {
        Object.assign(complement_config, JSON.parse(res.complement_config));
      }
      Object.assign(detail, complement_config);
      this.detail = detail;
      if (this.$route.query.edit) {
        this.showEdit();
      }
    },
    showEdit() {
      const form = clone(this.detail);
      form.rule_time = form.ruleTimeDetail;
      this.form = form;
      this.edit = true;
    },
    cancelEdit() {
      this.form = {};
      this.edit = false;
    },
    handleConfirm() {
      this.$refs.ruleForm.validate().then((data) => {
        this.$axios({
          url: `/admin/checkin-rule/${data.id}`,
          method: "PATCH",
          data,
        }).then(() => {
          this.$message.success("保存成功！");
          this.cancel();
          this.getDetail();
        });
      });
    },
    cancel() {
      this.cancelEdit();
      if (this.$route.query.edit) {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="less">
.footer {
  width: 100%;
}
.checkin-commute .set-info-ctx {
  flex: 1;
  min-width: 0;
}
</style>
